import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './ImageText.css';
import { BsCheckLg } from "react-icons/bs";
import { Link } from 'react-router-dom';

function ImageText({ data, left = true }) {
  // Check if data exists, if not return null
  if (!data) return null;

  // Function to check if the link is internal
  const isInternalLink = link => link.startsWith('/');

  // Function to check if the link is a PDF
  const isPDFLink = link => link.endsWith('.pdf');

  return (
    <Container className='imgtxt-container'>
      <Row className="ImageText-row">
        {left ? (
          <>
            <Col xs={12} md={6} style={{ margin: '10px 0' }}> {/* col 1 */}
              {data.image && <Image src={data.image} fluid className='ImageText-img' />}
            </Col>
            <Col xs={12} md={6} className='ImageText-description' style={{ margin: '10px 0' }}> {/* col 2 */}
              <h2 className='ImageText-title'>{data.title}</h2>
              <p className='ImageText-Paragraph'>{data.description}</p>
              {data.listItems && (
                <ul className='ImageText-List'>
                  {data.listItems.map((listItem, i) => (
                    <li key={i}>
                      <BsCheckLg className="li-promoter" /> <span>{listItem}</span>
                    </li>
                  ))}
                </ul>
              )}
              <div style={{ paddingTop: '10px' }}>
                {data.buttonName && data.buttonLink && (
                  isInternalLink(data.buttonLink) ? (
                    // Internal link, render in the same window
                    <Link to={data.buttonLink} className="ImageText-button">
                      {data.buttonName}
                    </Link>
                  ) : (
                    // External link, check if it's a PDF
                    isPDFLink(data.buttonLink) ? (
                      <a
                        href={data.buttonLink}
                        className="ImageText-button"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.buttonName}
                      </a>
                    ) : (
                      <a
                        href={data.buttonLink}
                        className="ImageText-button"
                      >
                        {data.buttonName}
                      </a>
                    )
                  )
                )}
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col xs={12} md={6} className='ImageText-description' style={{ margin: '10px 0' }}> {/* col 2 */}
              <h2 className='ImageText-title'>{data.title}</h2>
              <p className='ImageText-Paragraph'>{data.description}</p>
              {data.listItems && (
                <ul className='ImageText-List'>
                  {data.listItems.map((listItem, i) => (
                    <li key={i}>
                      <BsCheckLg className="li-promoter" /> <span>{listItem}</span>
                    </li>
                  ))}
                </ul>
              )}
              <div style={{ paddingTop: '10px' }}>
                {data.buttonName && data.buttonLink && (
                  isInternalLink(data.buttonLink) ? (
                    // Internal link, render in the same window
                    <Link to={data.buttonLink} className="ImageText-button">
                      {data.buttonName}
                    </Link>
                  ) : (
                    // External link, check if it's a PDF
                    isPDFLink(data.buttonLink) ? (
                      <a
                        href={data.buttonLink}
                        className="ImageText-button"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.buttonName}
                      </a>
                    ) : (
                      <a
                        href={data.buttonLink}
                        className="ImageText-button"
                      >
                        {data.buttonName}
                      </a>
                    )
                  )
                )}
              </div>
            </Col>
            <Col xs={12} md={6} style={{ margin: '10px 0' }}> {/* col 1 */}
              {data.image && <Image src={data.image} fluid className='ImageText-img' />}
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
}

export default ImageText;
