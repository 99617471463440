import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../PageCss/HansaFlexIndiaPages/Journey.css';
import JourneyCard from '../../Components/JourneyCard/JourneyCard';
import imgAU1980 from '../../assets/Journey/AU1980.png';
import imgAU1990 from '../../assets/Journey/AU1990.jpg';
import imgAU2000 from '../../assets/Journey/AU2000.jpg';
import imgAU2014 from '../../assets/Journey/AU2014.jpg';
import imgAU2015 from '../../assets/Journey/AU2015.jpg';
import imgAU2016 from '../../assets/Journey/AU2016.jpg'
import JourneyCardR from '../../Components/JourneyCard/JourneyCardR';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgHistoryStage from '../../assets/company-history.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

import imgBelgium_Journey from '../../assets/Journey/Belgium_Journey.png'

function Journey() {

    const isMobile = window.innerWidth <= 789;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
        AOS.init({
            // Global settings:
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,

            // Settings that can be overridden on per-element basis:
            offset: 300,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);



    return (
        <div className='journey-content'>
            <CenterStage imageSrc={imgBelgium_Journey} title="Come with us on our Journey !" paragraph="HANSA-FLEX Belgium has a rich history marked by strategic evolution and adaptability. Navigating through time, it has demonstrated resilience and innovation, continually meeting challenges and expanding its influence in the industry." />

            <div className='div-timeline-wrapper'>
                <br /><br /><br /><br />
                {isMobile ? <></> : <><div className='vertical-line'></div></>}

                <div>
                    <JourneyCard
                        title="1980"
                        description="Began operations, quickly becoming a key supplier in the hydraulic industry and Major supplier for Queensland Railways project."
                        imageUrl={imgAU1980}
                        additionalDescription="Trading Start: Hydraulic Connections & Rail Supply"
                    />
                </div>

                <div >
                    <JourneyCardR
                        title="1990"
                        description="Rebranded as Flanges Belgium, focusing on hydraulic industry imports and wholesale."
                        imageUrl={imgAU1990}
                        additionalDescription="Started trading as Flanges Belgium"
                    />
                </div>

                <div >
                    <JourneyCard
                        title="2000s"
                        description="Transitioned to a multi-national company, expanding product range. "
                        imageUrl={imgAU2000}
                        additionalDescription="Evolved into a multi-national company"
                    />
                </div>

                <div>
                    <JourneyCardR
                        title="2014"
                        description="Fully integrated into the global HANSA-FLEX network"
                        imageUrl={imgAU2014}
                        additionalDescription=" Acquired by HANSA-FLEX AG"
                    />
                </div>

                <div>
                    <JourneyCard
                        title="2015"
                        description="Acquired Rapid Hose Systems, enhancing market presence. "
                        imageUrl={imgAU2015}
                        additionalDescription="Purchased Rapid Hose Systems in Melbourne in 2015, April"
                    />
                </div>


                <div>
                    <JourneyCardR
                        title="2016"
                        description="Officially became HANSA-FLEX Belgium, aligning with the global brand on 1st January 2016"
                        imageUrl={imgAU2016}
                        additionalDescription="Renamed to HANSA-FLEX Belgium Pty Ltd"
                    />
                </div>
            </div>
        </div>
    );
}

export default Journey;
