import React, { useState } from 'react';


// YouTube Video 
//  <VideoPlayer
//  src="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
//  width="800px"
//  height="450px"
//  autoplay={false}
//  loop={false}
//  fallbackText="Your browser does not support this video."
//  onErrorFallback="Unable to load the video. Please check the link or try another video."
// />

// Local/Direct Video 
//  <VideoPlayer
//  src="https://www.w3schools.com/html/mov_bbb.mp4"
//  poster="https://via.placeholder.com/800x400.png?text=Video+Loading"
//  width="800px"
//  height="450px"
//  controls={true}
//  autoplay={false}
//  loop={false}
//  muted={false}
// /> 

const VideoPlayer = ({
  src,
  poster,
  width = "100%",
  height = "auto",
  controls = true,
  autoplay = false,
  loop = false,
  muted = false,
  fallbackText = "Your browser does not support the video tag.",
  onErrorFallback = "Unable to load the video. Please check your internet connection or try another video.",
}) => {
  const [hasError, setHasError] = useState(false);

  const isYouTubeLink = (url) => {
    return /youtube\.com|youtu\.be/.test(url);
  };

  const getYouTubeEmbedURL = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return match ? `https://www.youtube.com/embed/${match[1]}?autoplay=${autoplay ? 1 : 0}&loop=${loop ? 1 : 0}` : null;
  };

  if (isYouTubeLink(src)) {
    const embedURL = getYouTubeEmbedURL(src);

    if (!embedURL) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "#f0f0f0",
            color: "#333",
            fontSize: "16px",
            textAlign: "center",
            padding: "10px",
          }}
        >
          {onErrorFallback}
        </div>
      );
    }

    return (
      <div style={{ position: "relative", width, height }}>
        <iframe
          src={embedURL}
          width={width}
          height={height}
          allow="autoplay; fullscreen"
          frameBorder="0"
          allowFullScreen
          style={{ display: "block", width: "100%" }}
        ></iframe>
      </div>
    );
  }

  return (
    <div style={{ position: "relative", width, height }}>
      {!hasError ? (
        <video
          src={src}
          poster={poster}
          width={width}
          height={height}
          controls={controls}
          autoPlay={autoplay}
          loop={loop}
          muted={muted}
          onError={() => setHasError(true)}
          style={{ display: "block", width: "100%", height: "auto" }}
        >
          {fallbackText}
        </video>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "#f0f0f0",
            color: "#333",
            fontSize: "16px",
            textAlign: "center",
            padding: "10px",
          }}
        >
          {onErrorFallback}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
