import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

function PrivacyPolicyPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <SectionHeader title="Privacy Policy" />
            <TextCaption
                title="1. Inleiding"
                paragraph="Wij waarderen uw interesse in de online diensten van HANSA-FLEX AG (in wat volgt HANSA-FLEX genoemd). De bescherming van uw persoonlijke gegevens is voor ons erg belangrijk, en wij willen dat u zich zowel bij het bezoek aan onze websites als tijdens alle zakelijke en communicatieprocessen met ons goed en veilig voelt. De bescherming van uw gegevens en de geldende wettelijke voorschriften nemen wij daarom erg ernstig."
            />
            <TextCaption
                paragraph={"Met deze verklaring inzake gegevensbescherming (in wat volgt privacyverklaring) willen wij u uitvoerig informeren over de omgang met uw persoonlijke gegevens."}
            />
            <TextCaption
                paragraph={"Deze privacyverklaring vormt een aanvulling op onze algemene verkoopsvoorwaarden: https://www.hansa-flex.be/terms_and_conditions.html."}
            />
            <TextCaption
                paragraph={"De actuele versie van de privacyverklaring vindt u op de website van HANSA-FLEX onder het menupunt 'Privacy Policy': https://www.hansa-flex.be/data_privacyinformation.html."}
            />
            <TextCaption
                paragraph={"HANSA-FLEX behoudt zich het recht voor en heeft het recht om de inhoud van deze privacyverklaring te wijzigen. Dit kan gebeuren wanneer HANSA-FLEX nieuwe of gewijzigde diensten ter beschikking stelt, of als wettelijke veranderingen of veranderingen in de rechtspraak de aanpassing van deze privacyverklaring noodzakelijk of zinvol maken."}
            />
            <TextCaption
                paragraph={"Dit gebeurt alleen dan, wanneer de gerechtvaardigde belangen van HANSA-FLEX overwegen en dit voor de gebruiker redelijk is."}
            />

            <TextCaption
                title="2. Verantwoordelijke intstantie in de zin van Privacyverklaring (GDPR, vgl. AVG, Algemene Verordening Gegevensbescherming)"
            />

            <div style={{ marginTop: '20px', marginLeft: '15%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>HANSA-FLEX Hydraulics nv</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Industriepark-Drongen 12</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>9031 GENT-DRONGEN</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Belgium</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Tel.: +32 9 282 36 15</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>E-Mail: info@hansa-flex.be</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Internet: http://www.hansa-flex.be</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Managing Director: Tessa De Vos - BE 0454 891 101 - RPR Gent</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '15%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>HANSA-FLEX AG</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Zum Panrepel 44</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>D-28307 Bremen</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Bondsrepubliek Duitsland</p>

                <p style={{ marginTop: '8px', marginBottom: '0px' }}>Tel.: +49 421 48 90 70</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>E-Mail: info@hansa-flex.com</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Internet: http://www.hansa-flex.de</p>

                <p style={{ marginTop: '8px', marginBottom: '0px' }}>Directie: Thomas Armerding (voorzitter),  Christian-Hans Bültemeier, Florian Wiedemeyer</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Voorzitter van de raad van toezicht: Tim Hollweg</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Kantongerecht Bremen HRB 26530 HB; zetel van de maatschappij is Bremen</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Btw-nr. DE170352164</p>
            </div>
            <br />
            <TextCaption
                subtitle="Contactpersoon voor gegevensbescherming (gemachtigde)"
                paragraph="Wij vinden uw vertrouwen belangrijk. Daarom willen wij u op elk moment in alle rede te woord staan met betrekking tot het verzamelen, verwerken en het gebruik van uw persoonlijke gegevens. Neem daarvoor contact op met onze gemachtigde voor gegevensbescherming."
            />

            <TextCaption
                subtitle="Gemachtigde voor gegevensbescherming van HANSA-FLEX"
            />

            <div style={{ marginTop: '20px', marginLeft: '15%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Tessa De Vos</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>HANSA-FLEX Hydraulics nv</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Industriepark-Drongen 12</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>B-9031 Drongen</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Belgium</p>

                <p style={{ marginTop: '8px', marginBottom: '0px' }}>Tel.: +32 9 282 36 15</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>E-Mail: privacy@hansaflex.com</p>
            </div>

            <div style={{ marginTop: '20px', marginLeft: '15%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Max Danne</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>HANSA-FLEX AG</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Zum Panrepel 44</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>D-28307 Bremen</p>
                <p style={{ marginTop: '8px', marginBottom: '0px' }}>Tel.: +49 421 48 90 7 221</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>E-Mail: datenschutz@hansa-flex.com</p>
            </div>

            <TextCaption
                title="3. Algemene principes"
                paragraph="Wij verzamelen, verwerken en gebruiken uw persoonlijke gegevens onder naleving van de geldende wettelijke voorschriften over gegevensbescherming van de Bondsrepubliek Duitsland, met name de Telemediengesetz (TMG, Duitse telemediawet), het Rundfunkstaatsvertrag (RStV, Duitse nationale omroepverdrag), Privacyverklaring (GDPR, Duitse AVG, Algemene Verordening Gegevensbescherming), de Telekommunikationsgesetz (TKG, Duitse wet inzake telecommunicatie) en dergelijke, en van de voorschriften inzake gegevensbescherming van de Europese Unie."
            />

            <TextCaption
                paragraph="Wij verzamelen en verwerken persoonlijke gegevens van onze gebruikers alleen, voor zover dit voor het beschikbaar stellen van een functionerende website en onze inhoud en diensten is vereist (art. 6 lid 1 par. f GDPR). Over dit verzamelen krijgt u uitleg onder 'Verzamelen van gegevens bij bezoek aan de website'."
            />

            <TextCaption
                paragraph="Verdere persoonlijke gegevens worden door ons alleen verzameld, verwerkt en gebruikt met uw eigen toestemming, wanneer u deze gegevens vrijwillig, bijvoorbeeld in het kader van een aanvraag, zoekactie, sollicitatie of bestelling via ons e-mailadres, ons contactformulier of via onze websites, bij de registratie voor de door HANSA-FLEX aangeboden diensten met verplichte registratie, aanmelding voor onze nieuwsbrief of voor de motivatie, inhoudelijke uitwerking of wijziging en afwikkeling van een contract over het gebruik van de door HANSA-FLEX aangeboden diensten met verplichte registratie en de aankoop van de door HANSA-FLEX aangeboden goederen en dienstverleningen en voor afrekeningsdoeleinden verstrekt (art. 6 lid 1 par. a GDPR)."
            />

            <TextCaption
                paragraph="Uw persoonlijke gegevens kunnen door andere gebruikers van de online aanbiedingen van HANSA-FLEX niet worden ingezien."
            />

            <TextCaption
                subtitle="Wettelijke basis voor de verwerking van gegevens:"
                paragraph="Voor zover wij voor de verwerkingsprocessen van persoonlijke gegevens een toestemming van de betreffende persoon inwinnen, dient art. 6 lid 1 par. a EU verordening inzake gegevensbescherming (GDPR) als wettelijke basis."
            />

            <TextCaption
                paragraph="Bij de verwerking van persoonlijke gegevens die vereist is voor de vervulling van een contract waarbij de contractant de betreffende persoon is, dient art. 6 lid 1 par. b GDPR als wettelijke basis. Dit geldt ook voor verwerkingsprocessen die zijn vereist voor maatregelen voorafgaand aan een contract."
            />

            <TextCaption
                paragraph="Voor zover een verwerking van persoonlijke gegevens vereist is voor de vervulling van een wettelijke verplichting die onze onderneming moet nakomen, dient art. 6 lid 1 par. c GDPR als wettelijke basis."
            />

            <TextCaption
                paragraph="In het geval dat zaken van levensbelang van de betreffende persoon of een andere natuurlijke persoon een verwerking van persoonlijke gegevens vereist maken, dient art. 6 lid 1 par. d GDPR als wettelijke basis."
            />

            <TextCaption
                paragraph="Als de verwerking is vereist omwille van een gerechtvaardigd belang van onze onderneming of een derde en de belangen, grondrechten en fundamentele vrijheden van de betreffende persoon het eerst genoemde belang niet overwegen, dan dient art. 6 lid 1 par. f GDPR als wettelijke basis voor de verwerking."
            />

            <TextCaption
                subtitle="Gerechtvaardigde belangen kunnen met name zijn:"
                bulletPoints={[
                    "de beantwoording van aanvragen,",
                    "de uitvoering van direct marketing maatregelen,",
                    "de beschikbaarstelling van diensten en/of informatie die voor u is bedoeld,",
                    "de verwerking en overdracht van persoonlijke gegevens voor interne resp. administratieve doeleinden,",
                    "de exploitatie en het beheer van onze website,",
                    "de technische support van de gebruikers,",
                    "het vermijden en verborgen houden van bedrog en strafbare feiten,",
                    "de beveiliging tegen uitval van betalingstransacties bij het inwinnen van inlichtingen over kredietwaardigheid bij aanvragen van leveringen en diensten; en/of",
                    "de verzekering van de veiligheid van het netwerk en de bescherming van gegevens, voor zover deze belangen telkens in harmonie zijn met het geldend recht en met de rechten en vrijheid van de gebruiker."
                ]}
            />

            <TextCaption
                paragraph="Wij zullen uw persoonlijke gegevens niet voor reclame of marketing doeleinden noch voor andere, met name commerciële en/of reclamedoeleinden, doorgeven aan derden, tenzij u voor het doorspelen van uw gegevens voor de genoemde doeleinden uitdrukkelijk toestemming heeft gegeven, of als wij een overeenstemming hebben gevonden over de orderverwerking met een door ons in de hand genomen dienstverlener, of indien de overdracht van gegevens op grond van wettelijke voorschriften uitdrukkelijk toegelaten resp. zelfs voorgeschreven is."
            />

            <TextCaption
                subtitle="Uw rechten als betrokkene"
                paragraph="HANSA-FLEX wil u hier informeren over uw rechten."
            />

            <TextCaption
                paragraph="U heeft het recht,"
                bulletPoints={[
                    "om van ons een bevestiging te verlangen of uw betreffende persoonlijke gegevens worden verwerkt; als dit het geval is, dan heeft u een recht op informatie over deze persoonlijke gegevens en op de in art. 15 GDPR in detail opgesomde inlichtingen.",
                    "om de overhandiging van de u betreffende gegevens binnen de beperkingen van art. 20 GDPR in een gangbaar elektronisch, machine leesbaar gegevensformaat te verlangen. Dit houdt ook de overhandiging (voor zover mogelijk) aan een andere direct door u aangewezen verantwoordelijke in.",
                    "om van ons de rechtzetting van uw gegevens te verlangen, indien deze onjuist, niet van toepassing en/of onvolledig zijn. Tot rechtzetting behoort ook de vervollediging door verklaringen of mededeling.",
                    "om van ons te verlangen dat u betreffende persoonlijke gegevens onmiddellijk worden verwijderd, indien een van de in art. 17 GDPR in detail beschreven redenen van toepassing is.",
                    "om elke door u verleende toestemming met effect voor de toekomst te herroepen, zonder dat voor u daaruit nadelen voortvloeien.",
                    "om van ons de beperking van de verwerking te verlangen, wanneer er sprake is van een van de art. 18 par opgesomde voorwaarden.",
                    "om redenen als gevolg van een heel bijzondere situatie op elk moment bezwaar te maken tegen de verwerking van u betreffende persoonlijke gegevens. Wij verwerken de persoonlijke gegevens dan niet meer, tenzij wij dwingende redenen ter bescherming kunnen voorleggen die hoger wegen dan uw belangen, rechten en vrijheden, of als de verwerking daartoe dient om juridische eisen te doen gelden, uit te oefenen of te verdedigen (art. 21 GDPR)."
                ]}
            />

            <TextCaption
                paragraph="Gegevens die zijn onderworpen aan een wettelijke bewaringstermijn, mogen wij helaas niet verwijderen. Indien u wilt dat wij in de toekomst geen gegevens van u meer registreren of u niet meer contacteren, dan nemen wij u graag op in een lijst met geblokkeerde adressen."
            />

            <TextCaption
                paragraph="Indien u van mening bent dat een verwerking van de u betreffende persoonlijke gegevens in strijd is met de GDPR, dan kunt u zich zonder nadelige gevolgen wenden tot een andere overheidsinstantie of rechtsbijstand zoeken bij de volgende instanties:"
                bulletPoints={[
                    "per post aan HANSA-FLEX (zie impressum),",
                    "per e-mail aan privacy@hansaflex.com of datenschutz@hansa-flex.com.",
                    "bij een bevoegde toezichthoudende instantie in de lidstaat van uw verblijfplaats, uw werkplek of de plaats van een vermoedelijke overtreding"
                ]}
            />

            <TextCaption
                title="4. Verzameling van gegevens bij bezoek aan de website"
                paragraph="In principe kunt U onze website op www.hansa-flex.be bezoeken zonder gegevens over uw persoon te verstrekken. Elke toegang tot onze website en elke oproep van de daar gearchiveerde bestanden wordt echter geprotocolleerd en opgeslagen in een protocolbestand, de server-logfile. Wij verzamelen hierbij automatisch in onze server-logfiles alleen die gegevens die uw internetbrowser aan ons overdraagt en slaan deze op."
            />

            <TextCaption
                paragraph="Dit zijn onder andere:"
                bulletPoints={[
                    "browsertype / -versie",
                    "gebruikte besturingssysteem",
                    "referrer URL (de daarvoor bezochte internetpagina)",
                    "hostnaam van de computer die toegang probeert te krijgen (IP-adres)",
                    "datum en tijd van de serveraanvraag",
                    "naam van het opgeroepen bestand",
                    "overgedragen hoeveelheid gegevens",
                    "melding over succesvolle toegang"
                ]}
            />

            <TextCaption
                paragraph="Zonder deze gegevens zou het technisch gedeeltelijk niet mogelijk zijn om de inhoud van de webpagina weer te geven en voor te stellen. In zoverre is de registratie van de gegevens dwingend noodzakelijk. Wij kunnen deze gegevens niet toekennen aan bepaalde personen. Deze gegevens worden niet samengevoegd met andere gegevensbronnen. Onder voorbehoud van eventuele wettelijke plichten tot bewaring worden de IP-adressen van gebruikers na beëindiging van het gebruik geanonimiseerd of verwijderd. Bij een anonimisering worden de IP-adressen zo gewijzigd, dat de afzonderlijke gegevens over persoonlijke of zakelijke verhoudingen niet meer of alleen met een naar verhouding onredelijke besteding van tijd, kosten en arbeidskracht aan een bepaalde of identiceerbare natuurlijke persoon kunnen worden toegekend."
            />

            <TextCaption
                paragraph="De tijdelijke opslag en statistische evaluatie van de gegevens die niet aan een bepaalde persoon kunnen worden toegekend, dient uitsluitend voor de beveiliging van het systeem en ter bescherming van de gegevens (bijv. de herkenning van eventueel foutieve links en fouten in programma's, en aanvallen op het systeem), en ter verbetering van ons aanbod (bijv. door vast te stellen welke aanbiedingen van goederen en dienstverleningen bijzonder geliefd zijn)."
            />

            <TextCaption
                paragraph="Wij behouden ons voor om de gegevens uit de serverlogfiles te gebruiken, wanneer op grond van bepaalde concrete feiten het gemotiveerde vermoeden bestaat dat gebruikers onze websites gebruiken in strijd met de wet of het contract, of er een beroep op doen met als doel eventueel overeengekomen tarieven of vergoedingen niet of niet volledig te betalen, voor zover dit voor de gerechtelijke vervolging is vereist."
            />

            <TextCaption
                paragraph="Andere gegevens over uw persoon worden niet geregistreerd, tenzij deze gegevens door u vrijwillig worden verstrekt, bijv. in het kader van een aanvraag, zoekactie of bestelling via ons e-mailadres."
            />

            <TextCaption
                paragraph="Wettelijke basis voor de tijdelijke opslag van de gegevens en de logfiles is art. 6 lid 1 par. f GDPR met de bovengenoemde gerechtvaardigde belangen."
            />

            <TextCaption
                title="5. Diensten en de daarbij verzamelde persoonlijke gegevens"
                paragraph="Op de HANSA-FLEX websites bieden wij u tal van services aan, waarbij u indien u die wilt gebruiken enkele persoonlijke gegevens moet verstrekken."
            />

            <TextCaption
                bulletPoints={[
                    "Registratie in de HANSA-FLEX online shop https://shop.hansa-flex.be",
                    "'Mijn gebruikersaccount' bij de HANSA-FLEX online shop https://shop.hansa-flex.be",
                    "Opslag en toegang tot de tekst van een contract",
                    "Registratie in het HANSA-FLEX klantenportaal https://my.hansa-flex.be",
                    "'Mijn profiel' in het HANSA-FLEX klantenportaal https://my.hansa-flex.be",
                    "Gebruik van onze X-CODE databank voor productidentificatie",
                    "Opgaven bij gebruik van onze configurator voor de vervaardiging van armaturen",
                    "Gebruik van ons call-back formulier",
                    "Gebruik van ons contact formulier",
                    "Gebruik van ons feedback formulier",
                    "Gebruik van ons praktijkhandboek bestelformulier",
                    "Gebruik van ons adresboek bestelformulier",
                    "Gebruik van ons bestelformulier voor ons klantenmagazine 'HYDRAULIKPRESSE' (HYDRAULISCHE PERS)"
                ]}
            />

            <TextCaption
                paragraph="Om gebruik te kunnen maken van deze diensten worden enkele persoonlijke gegevens verzameld, die u zelf invoert en waardoor u ook toestemming geeft voor de verwerking. Wettelijke basis voor dit soort verwerking is art. 6 lid 1 par. a GDPR. Gelieve ook de paragraaf 'Uw rechten als betrokkene' in deze verklaring inzake gegevensbescherming in acht te nemen."
            />

            <TextCaption
                title="6. Sollicitaties"
                paragraph="U kunt bij ons solliciteren voor openstaande vacatures, opleidingen, practica voor scholieren en studenten en voor functies voor afgestudeerden / bachelors / masters, of op eigen initiatief solliciteren."
            />

            <TextCaption
                paragraph="Wij wijzen er uitdrukkelijk op dat sollicitaties, met name curricula vitae, getuigschriften en andere aan ons overgedragen gegevens en documenten, met name gevoelige gegevens over mentale en lichamelijke gezondheid, ras of etnische afkomst, over politieke, religieuze of filosofische overtuigingen, lidmaatschap van een vakbond of politieke partij of over seksuele geaardheid kunnen bevatten. Gelieve er verder rekening mee te houden dat de overdracht van de gegevens per e-mail onversleuteld gebeurt, en dat de gegevens daarom onder bepaalde omstandigheden door onbevoegden ter kennis genomen of vervalst kunnen worden. Indien u ons zulke gegevens en documenten bij uw sollicitatie overdraagt per e-mail, dan verklaart u zich er uitdrukkelijk mee akkoord dat wij deze gegevens en documenten met het oog op de afwikkeling van de sollicitatie mogen verzamelen, verwerken en gebruiken. Het verzamelen, verwerken en het gebruik van deze gegevens en documenten gebeurt in overeenstemming met deze verklaring inzake gegevensbescherming en de verder geldende wettelijke voorschriften."
            />

            <TextCaption
                paragraph={`Bij sollicitaties met een concrete referentie naar een functie (directe sollicitatie) worden uw gegevens en documenten toegankelijk gemaakt voor zowel de uitschrijvende afdeling als de bevoegde personeelsdienst. Indien u bij ons heeft gesolliciteerd zonder concrete referentie naar een functie (spontane sollicitatie), dan worden uw gegevens en documenten toegankelijk gemaakt voor zowel de centrale personeelsdienst als decentrale personeelsdiensten van onze onderneming, voor zover deze vacatures beantwoorden aan uw profiel. Uw gegevens en documenten worden niet doorgegeven aan derden.`}
            />

            <TextCaption
                paragraph={"Het verwijderen van de overgedragen gegevens en documenten gebeurt bij de afwijzing of negatieve beslissing naar aanleiding van uw sollicitatie ten vroegste 3 maanden na beëindiging van de sollicitatieprocedure. Dit geldt niet indien wettelijke voorschriften het verwijderen niet toestaan of als de verdere opslag met het oog op bewijsvoering is vereist, of indien u heeft ingestemd met een langere opslag. Daarna worden de resultaten alleen nog in geanonimiseerde vorm, dus zonder de vermelding van uw naam, opgeslagen voor verdere statistische evaluatie. Uit deze statistische datarecord kan geen natuurlijke persoon worden herleid, en deze dient louter als grondslag voor statistische evaluaties. Wanneer u instemt met de registratie van uw sollicitatie in onze databank onder de voorwaarde dat de door u overgedragen gegevens en documenten in de toekomst ook bij de invulling van andere vacatures in aanmerking moeten worden genomen, dan worden uw gegevens en documenten gedurende 12 maanden in de databank van sollicitanten opgeslagen en daarna ofwel automatisch verwijderd of alleen nog in geanonimiseerde vorm, dus zonder de vermelding van uw naam, opgeslagen voor verdere statistische evaluatie. Als na uw sollicitatie een contract wordt afgesloten, dan kunnen uw gegevens met het oog op het gebruikelijke organisatie- en beheerproces met inachtneming van de geldende wettelijke voorschriften opgeslagen en gebruikt worden."}
            />

            <TextCaption
                paragraph={"U heeft op elk moment de mogelijkheid om uw sollicitatie volledig of gedeeltelijk in te trekken. U kunt ook op elk moment verlangen dat alle of sommige van de door u overgedragen gegevens en bestanden uit onze databank van sollicitanten verwijderd of gewijzigd worden. Bepaalde gegevens over uw sollicitatie moeten echter gedurende een beperkte periode van 3 maanden worden opgeslagen om wettelijke voorschriften, met name de bewijsplicht uit de Allgemeine Gleichbehandlungsgesetz (AGG, Duitse Algemene wet gelijke behandeling), na te komen."}
            />

            <TextCaption
                title="7. Gebruik en doorgeven van persoonlijke gegevens"
                paragraph="Wij geven uw persoonlijke gegevens in principe niet door aan derden, tenzij u uitdrukkelijk heeft ingestemd met het doorgeven van uw gegevens voor de genoemde doeleinden, of als het doorgeven op grond van wettelijke voorschriften uitdrukkelijk toegelaten resp. voorgeschreven is."
            />

            <TextCaption
                subtitle="Bij de afwikkeling van een contract betrokken dienstverleners"
                paragraph="HANSA-FLEX mag volgens art. 6 lid 1 par. b GDPR en art. 6 lid 1 par. f GDPR de in het kader van de uitvoering van een contract verzamelde gegevens – voor zover dit is vereist – overdragen aan de bij de afwikkeling van het contract betrokken filialen van firma's, ondernemingen van het concern en aan partnerfirma's en vertegenwoordigingen, en ook aan andere externe dienstverleners waarop wij een beroep doen voor de vervulling van het contract, voor zover het gebruiksdoel van de gegevens integer blijft. Hiertoe behoren met name de met de levering van bestelde goederen belaste expeditiebedrijven en de met de afwikkeling van de betaling belaste instellingen, de met de uitvoering van onderhouds-, reparatiewerkzaamheden en ander werk en dienstverleningen belaste filialen van de firma http://www.hansa-flex.com/niederlassungen.html, ondernemingen van het concern http://www.hansa-flex.com/unternehmen/unternehmensgruppe.html en partnerfirma's en vertegenwoordigingen http://www.hansa-flex.com/niederlassungen/partner.html en overige externe dienstverleners. In deze gevallen sturen wij de gegevens door conform de richtlijnen van de geldende wettelijke voorschriften inzake gegevensbescherming (met name de Privacyverklaring - GDPR, Duitse AVG, Algemene Verordening Gegevensbescherming). De omvang van de overgedragen gegevens wordt tot een minimum beperkt. Deze ondernemingen mogen uw gegevens alleen gebruiken om de opdracht af te wikkelen en niet voor andere doeleinden, en zijn gebonden aan een overeenkomst inzake de verwerking van gegevens in het kader van een opdracht volgens art. 28 GDPR."
            />

            <TextCaption
                subtitle="Gebruik van de gegevens voor doeleinden van eigen advies, eigen reclame en eigen marktonderzoek"
                paragraph="Het is ons volgens art. 6 lid 1 par. f GDPR toegelaten om uw persoonlijke gegevens met het oog op eigen advies, eigen reclame en eigen marktonderzoek, vooral echter voor op de vraag afgestemde vormgeving van de door ons aangeboden goederen en dienstverleningen te verzamelen, verwerken en te gebruiken. Natuurlijk kunt u tegen het verzamelen, verwerken en het gebruik van uw persoonlijke gegevens voor reclamedoeleinden op elk moment met effect voor de toekomst bezwaar maken resp. uw toestemming herroepen. Wij zullen u dan geen reclame-informatie meer toesturen."
            />

            <TextCaption
                paragraph={"Bovendien behandelen wij de gegevens vertrouwelijk en in overeenstemming met de geldende wettelijke voorschriften inzake gegevensbescherming. Wij zullen deze gegevens noch de inhoud van uw berichten zonder uw toestemming doorgeven aan derden, tenzij u uitdrukkelijk heeft ingestemd met het doorgeven van uw gegevens voor de genoemde doeleinden of het doorgeven op grond van wettelijke voorschriften uitdrukkelijk toegelaten resp. zelfs voorgeschreven is."}
            />

            <TextCaption
                paragraph={"Dit verbod op het doorgeven van gegevens geldt echter in zoverre niet, als wij op bevel van de bevoegde instanties verplicht zijn om derden, met name overheidsinstanties, in individuele gevallen informatie over gegevens te verstrekken, indien dit met het oog op strafvervolging, om gevaren af te weren door de politiediensten van de deelstaten, om de wettelijke plichten gesteld door de Binnenlandse Veiligheidsdienst van de BRD en de deelstaten, de geheime inlichtingendienst of de militaire contraspionage of door de federale recherche in het kader van hun taak van het afweren van gevaren van het internationale terrorisme na te komen, of voor de uitoefening van rechten op geestelijke eigendom of op grond van andere in die zin toepasselijke wettelijke voorschriften vereist is. Hiervoor is uw toestemming niet nodig."}
            />

            <TextCaption
                paragraph={"Elke verzameling, verwerking of gebruik van uw gegevens dat verder gaat dan het hier beschrevene, gebeurt alleen met uw toestemming volgens art. 6 lid 1 par. a GDPR."}
            />

            <TextCaption
                subtitle={"Export en verwerking van de gegevens in staten buiten de Europese Economische Ruimte"}
                paragraph={"HANSA-FLEX is een wereldwijd actieve onderneming en exploiteert wereldwijd tal van websites. De gegevens van websites voor in Duitsland en in de EU gevestigde filialen en ondernemingen van het concern worden uitsluitend opgeslagen op servers in Duitsland of de EU, ook gegevens die aan deze websites of aan ons worden overgedragen. De gegevens van websites voor in staten buiten de Europese Economische Ruimte gevestigde filialen en ondernemingen van het concern kunnen ook op servers buiten Duitsland en de EU zijn opgeslagen, ook gegevens die aan deze websites of aan ons worden overgedragen."}
            />

            <TextCaption
                paragraph={"Wij mogen de door u vrijwillig, bijvoorbeeld in het kader van een aanvraag, zoekactie, sollicitatie of bestelling via ons e-mailadres enz. verstrekte persoonlijke gegevens ook overdragen aan filialen gevestigd in landen buiten de Europese Economische Ruimte http://www.hansa-flex.com/niederlassungen.html, ondernemingen van het concern http://www.hansa-flex.com/unternehmen/unternehmensgruppe.html en partnerfirma’s en vertegenwoordigingen http://www.hansa-flex.com/niederlassungen/partner.html en aan andere externe dienstverleners, wanneer hiermee een zakelijke en lokale relatie bestaat en de overdracht van de gegevens voor de beantwoording/behandeling van een aanvraag, zoekactie, sollicitatie, de registratie voor de door HANSA-FLEX aangeboden diensten met verplichte registratie, aanmelding voor onze nieuwsbrief of voor de motivatie, inhoudelijke uitwerking of wijziging en afwikkeling van een contract over het gebruik van de door HANSA-FLEX aangeboden goederen en diensten met verplichte registratie en voor afrekeningsdoeleinden vereist is. In dit geval kunnen uw persoonlijke gegevens ook worden opgeslagen op servers buiten Duitsland of de EU."}
            />

            <TextCaption
                paragraph={"Als de ontvanger van de gegevens zijn zetel heeft in een lidstaat van de Europese Unie (EU) of een verdragsstaat van de overeenkomst van de Europese Economische Ruimte (EER), dan moet op grond van recht dat geldt binnen de gemeenschap ervan worden uitgegaan dat een toereikend niveau van gegevensbescherming bij de ontvanger is gegarandeerd. Daarnaast heeft de EU-commissie voor enkele niet-EU-/EER-landen besloten dat hun rechtsorde over de hele lijn een adequaat niveau van gegevensbescherming verzekert, zodat ook bij in deze landen gevestigde ontvangers van gegevens een toereikend niveau van gegevensbescherming mag worden verondersteld. Hiertoe behoren de volgende landen: Andorra, Argentinië, Australië, Faröer Eilanden, Guernsey, Isle of Man, Israël, Jersey, Canada, Nieuw-Zeeland, Zwitserland, Uruguay, VS (Safe Harbor) http://ec.europa.eu/justice/data-protection/document/international-transfers/adequacy/index_en.htm. Indien de ontvanger van gegevens zich in een ander land (derde staat) dan de boven genoemde landen bevindt, dan hebben wij om een adequaat niveau van gegevensbescherming te garanderen ofwel met de ontvanger een contract met EU standaard verdragsclausules afgesloten, of de ontvanger heeft zich onderworpen aan de Safe Harbor principes voor ondernemingen in de VS, of wij zijn met de ontvanger regelingen overeengekomen voor de omgang met persoonlijke gegevens (Binding Corporate Rules)."}
            />

            <TextCaption
                paragraph={"Het is de gebruiker bekend dat in de landen buiten de Europese Economische Ruimte mogelijkerwijs een lager niveau van gegevensbescherming dan in de landen van de Europese Unie bestaat."}
            />

            <TextCaption
                title={"8. Cookies"}
                paragraph={"Onze websites gebruiken op meerdere plaatsen zogenaamde cookies. Een cookie (komt ongeveer overeen met ‘profielbestand’) is een klein tekstbestand, dat een server archiveert op de harde schijf van de computer van de gebruiker en dat de internetbrowser van de gebruiker opslaat, om daar tijdelijk beperkt informatie beschikbaar te houden die nodig is voor het gebruik van de betreffende server. Wij zetten cookies in om processen binnen het internetaanbod van HANSA-FLEX te herkennen en aan te sturen en voor de tijdelijke opslag van sessiegegevens (met name voor de aanmelding en om uw identiteit te herkennen). Deze cookie is dwingend noodzakelijk voor het goede functioneren van de door ons aangeboden diensten met registratieplicht van het internet portaal. U kunt uw internetbrowser zo instellen, dat u wordt geïnformeerd over het instellen van cookies, van geval tot geval beslissen of u daarmee akkoord gaat, of het accepteren van cookies in principe uitsluiten. Hoe u de cookiefunctie in uw internetbrowser kunt instellen, vindt u in de helpfunctie van uw internet browser beschreven. Het accepteren van deze cookie kan door u weliswaar worden geweigerd, leidt echter onvermijdelijk tot systeemfouten, die door ons niet verholpen of gecorrigeerd kunnen worden. De meeste van de door ons gebruikte cookies zijn zogenaamde ‘sessie cookies’. Deze worden na het einde van uw bezoek automatisch verwijderd. Onze cookies richten op uw computer geen schade aan en bevatten geen virussen, Trojaanse paarden of dergelijke schadelijke programma’s."}
            />

            <TextCaption
                paragraph={"De wettelijke basis voor de verwerking van persoonlijke gegevens met gebruik van cookies is art. 6 lid 1 par. f GDPR. De wettelijke basis voor de verwerking van persoonlijke gegevens met gebruik van technisch noodzakelijke cookies is art. 6 lid 1 par. f GDPR. De wettelijke basis voor de verwerking van persoonlijke gegevens met gebruik van cookies voor analysedoeleinden is als hiervoor toestemming van de gebruiker voorligt art. 6 lid 1 par. a GDPR."}
            />

            <TextCaption
                title={"9. Niewsbrief"}
                paragraph={"HANSA-FLEX Hydraulics NV informeert bestaande klanten eveneens regelmatig via nieuwsbrieven in een B2B-context met als grondslag de contractuele samenwerking (art. 6 lid 1 par. b GDPR). Bij elke nieuwsbrief heeft u de mogelijkheid om u hierop uit te schrijven, in dat geval ontvangt u geen verdere nieuwsbrieven. Uw contactgegevens blijven wel nog bewaard binnen onze ERP-toepassing, zodat we nog steeds andere diensten kunnen blijven verzorgen. Indien u dit niet wenst, dan kan u ons dit laten weten via de contactinformatie verder vermeld in deze verklaring."}
            />

            <TextCaption
                title={"10.  Google Analytics"}
                paragraph={"Deze website gebruikt Google Analytics, een webanalysedienst van Google Inc. (‘Google’). Google Analytics maakt gebruik van zgn. ‘cookies’, tekstbestanden die op uw computer worden opgeslagen en die een analyse mogelijk maken van hoe u de website gebruikt. De door de cookie gegenereerde informatie over uw gebruik van deze website wordt in de regel overgedragen aan een server van Google in de VS en daar opgeslagen. In het geval van activering van de IP-anonimisering op deze website wordt uw IP-adres door Google echter binnen lidstaten van de Europese Unie of in andere verdragsstaten van de overeenkomst van de Europese Economische Ruimte eerst afgekort. Slechts in uitzonderingsgevallen wordt het volledige IP-adres overgedragen aan een server van Google in de VS en daar afgekort. Wij wijzen u erop dat de IP-anonimisering op deze website actief is. Op deze webpagina werd Google Analytics uitgebreid met de code ‘gat._anonymizeIp()’ om een geanonimiseerde registratie van IP-adressen (zogenaamde IP-masking) te garanderen. In opdracht van de exploitant van deze website zal Google deze informatie gebruiken om uw gebruik van de website te evalueren, om rapporten over de website activiteiten samen te stellen en om andere diensten in verband met het gebruik van de website te verlenen aan de exploitant van de website. Het in het kader van Google Analytics door uw browser overgedragen IP-adres wordt niet samengevoegd met andere gegevens van Google."}
            />

            <TextCaption
                paragraph={"U kunt de opslag van de cookies verhinderen door de software van uw browser navenant in te stellen; we willen er echter op wijzen dat u in dat geval eventueel niet alle functies van deze website in hun volle omvang zult kunnen benutten. Daarnaast kunt u de registratie van de door de cookie gegenereerde en op uw gebruik van de website betrekking hebbende gegevens (incl. uw IP-adres) aan Google en de verwerking van deze gegevens door Google verhinderen door de onder de volgende link beschikbare browser plug-in te downloaden en te installeren: tools.google.com/dlpage/gaoptout ."}
            />

            <TextCaption
                paragraph={"Meer informatie over de gebruiksvoorwaarden en over gegevensbescherming bij het gebruik van Google Analytics vindt u op: http://www.google.com/analytics/terms/de.html resp. op http://www.google.com/policies/privacy/"}
            />

            <TextCaption
                title="11. Google Maps"
                paragraph="Op deze webpagina maken wij gebruik van het aanbod van Google Maps. Daardoor kunnen wij u interactieve kaarten direct op de website laten zien en stellen wij u in staat om de kaartfunctie comfortabel te benutten."
            />

            <TextCaption
                paragraph="Door het bezoek aan de website ontvangt Google de informatie dat u de betreffende subpagina van onze website heeft opgeroepen. Dit gebeurt onafhankelijk van het feit of Google een gebruikersaccount beschikbaar stelt via welke u bent ingelogd, of er geen gebruikersaccount bestaat. Wanneer u bij Google bent ingelogd, dan worden uw gegevens direct toegekend aan uw account. Wanneer u de toekenning aan uw profiel bij Google niet wenst, dan moet u zich vóór het oproepen van een kaart uitloggen. Google slaat uw gegevens op als gebruiksprofielen en gebruikt deze met het oog op reclame, marktonderzoek en/of voor op de vraag afgestemde vormgeving van zijn website. Zo'n evaluatie gebeurt met name (zelfs voor niet ingelogde gebruikers) voor het aanbieden van op de vraag afgestemde reclame en om andere gebruikers van het sociale netwerk te informeren over uw activiteiten op onze website. U komt een herroepingsrecht toe tegen het aanmaken van deze gebruikersprofielen, waarbij u zich voor de uitoefening daarvan moet richten tot Google."
            />

            <TextCaption
                paragraph="Het gebruik van Google Maps gebeurt in het belang van een aantrekkelijke voorstelling van onze online diensten en om de door ons op de website vermelde plaatsen gemakkelijk te kunnen vinden. Dit vormt een gerechtvaardigd belang in de zin van art. 6 lid 1 par. f GDPR (Duitse AVG, Algemene Verordening Gegevensbescherming)."
            />

            <TextCaption
                paragraph="Meer informatie over doel en omvang van het verzamelen van gegevens en de verwerking daarvan door Google Maps ontvangt u in de verklaringen inzake gegevensbescherming. Daar krijgt u ook meer informatie over uw rechten en instellingsmogelijkheden in dezen ter bescherming van uw privé sfeer: www.google.de/intl/de/policies/privacy. Google verwerkt uw persoonlijke gegevens ook in de VS en heeft zich onderworpen aan het EU-US Privacy Shield, www.privacyshield.gov/EU-US-Framework."
            />

            <TextCaption
                paragraph="Google Maps wordt geëxploiteerd door Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA"
            />

            <TextCaption
                title="12. Facebook"
                paragraph="Wij gebruiken op deze pagina zgn. plug-ins van het sociale netwerk Facebook. De Facebook plug-ins herkent men aan het Facebook logo of aan de 'Like button' (ook 'Vind ik leuk knop' genoemd). Wanneer de gebruiker deze pagina bezoekt, dan wordt via de plug-in een directe verbinding tussen zijn browser en de Facebook-server gemaakt. Facebook ontvangt daardoor de informatie dat de gebruiker met zijn IP-adres deze pagina heeft bezocht. Wanneer de gebruiker de Facebook 'Like knop' aanklikt terwijl hij is ingelogd bij zijn Facebook account, dan kan hij de inhoud van deze pagina's linken aan zijn Facebook profiel. Daardoor kan Facebook het bezoek aan deze pagina's toekennen aan de gebruikersaccount van de gebruiker."
            />

            <TextCaption
                paragraph="Wij wijzen erop dat wij als aanbieder van de pagina's niet in kennis worden gesteld van de inhoud van de overgedragen gegevens en het gebruik daarvan door Facebook. Wanneer de gebruiker niet wenst dat Facebook het bezoek aan deze pagina's kan toekennen aan zijn Facebook gebruikersaccount, dan moet hij zich eerst uitloggen uit zijn Facebook gebruikersaccount."
            />

            <TextCaption
                paragraph="Informatie inzake gegevensbescherming van Facebook: www.facebook.com/policy.php"
            />

            <TextCaption
                paragraph="Facebook wordt geëxploiteerd door Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA"
            />

            <TextCaption
                title="13. Youtube"
                paragraph="Wij hebben YouTube video's geïntegreerd in ons online aanbod, die zijn opgeslagen op www.youtube.com en vanuit onze website direct kunnen worden afgespeeld. Deze zijn allemaal geïntegreerd in de 'modus geavanceerde gegevensbescherming', d.w.z. dat er geen gegevens over u als gebruiker aan YouTube worden overgedragen, wanneer u de video's niet afspeelt. Pas wanneer u de video's afspeelt, worden gegevens overgedragen. Op deze gegevensoverdracht hebben wij geen invloed. Dit gebeurt onafhankelijk van het feit of YouTube een gebruikersaccount beschikbaar stelt via welke u bent ingelogd, of er geen gebruikersaccount bestaat. Wanneer u bij Google bent ingelogd, dan worden uw gegevens direct toegekend aan uw account."
            />

            <TextCaption
                paragraph="Wanneer u de toekenning aan uw profiel bij YouTube niet wenst, dan moet u zich vóór het afspelen van een video uitloggen bij YouTube. YouTube slaat uw gegevens op als gebruiksprofielen en gebruikt deze met het oog op reclame, marktonderzoek en/of voor op de vraag afgestemde vormgeving van zijn website. U komt een herroepingsrecht toe tegen het aanmaken van deze gebruikersprofielen, waarbij u zich voor de uitoefening daarvan moet richten tot YouTube."
            />

            <TextCaption
                paragraph="Meer informatie over doel en omvang van het verzamelen van gegevens en de verwerking daarvan door YouTube ontvangt u in de verklaring inzake gegevensbescherming. Daar krijgt u ook meer informatie over uw rechten en instellingsmogelijkheden ter bescherming van uw privé sfeer: https://policies.google.com/privacy. Google verwerkt uw persoonlijke gegevens ook in de VS en heeft zich onderworpen aan het EU-US Privacy Shield, www.privacyshield.gov/EU-US-Framework."
            />

            <TextCaption
                paragraph="YouTube wordt geëxploiteerd door Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA"
            />

            <TextCaption
                title="14. Opslag van bankgegevens"
                paragraph="Indien wij als betalingswijze domiciliëring aanbieden en hiervoor geen dienstverlener voor betalingsverkeer zouden moeten gebruiken, dan is de gebruiker, die voor deze betalingswijze kiest, verplicht om HANSA-FLEX een geldige domiciliëringsmachtiging voor zijn bankrekening te verstrekken. De gebruiker moet hierbij onder meer de volgende gegevens meedelen:"
                bulletPoints={[
                    "Houder van de rekening",
                    "Rekeningnummer",
                    "Naam van de bank",
                    "Bankcode van de bank"
                ]}
            />

            <TextCaption
                paragraph="De bankgegevens kunnen door andere gebruikers nooit en te nimmer worden ingezien."
            />

            <TextCaption
                subtitle="Internet betalingssystemen"
                paragraph="Wij wijzen erop dat voor de betaling van onze internetdiensten naast andere betalingswijzen in sommige gevallen ook internetbetalingssystemen worden aangeboden, wier exploitanten mogelijkerwijs ook buiten de EU zijn gevestigd. Deze ondernemingen slaan daarbij met name op de voor de betaling relevante gegevens van de bestellende gebruiker op de servers, eventueel ook buiten de EU. Hierop hebben wij geen invloed."
            />

            <TextCaption
                title="15. Bescherming van kinderen en jongeren"
                paragraph="De bescherming van de privésfeer van kinderen en jongeren ligt ons nauw aan het hart. Personen onder 18 jaar mogen in principe zonder toestemming van hun wettige vertegenwoordiger geen gegevens overdragen aan onze websites. Wij zullen geen informatie verzamelen, verwerken en gebruiken van personen waarvan ons bekend is dat deze jonger zijn dan 16 jaar, zonder daarvoor de aantoonbare toestemming te hebben ingewonnen van de wettige vertegenwoordiger."
            />

            <TextCaption
                title="16. Gegevensbescherming"
                paragraph="Uw persoonlijke gegevens worden zowel bij de registratie voor door ons aangeboden diensten met verplichte registratie als bij de aanmelding in de interne gebruikerservice zone zowel van de online shop op https://shop.hansa-flex.be als van het klantenportaal op https://my.hansa-flex.com versleuteld door middel van SSL certificaat (2048 bit) overgedragen via het internet. Wij beveiligen onze website en overige systemen door technische en organisatorische maatregelen tegen verlies, vernietiging, toegang, wijziging of verspreiding van uw gegevens door onbevoegde personen. Ondanks regelmatige controles is een volledige bescherming tegen alle gevaren echter niet mogelijk. De toegang tot uw account in de interne gebruikerservice zone zowel van de online shop op https://shop.hansa-flex.be als van het klantenportaal op https://my.hansa-flex.com is alleen mogelijk na invoer van uw persoonlijke wachtwoord. U moet uw toegangsinformatie altijd vertrouwelijk behandelen en het browservenster sluiten als u de communicatie met ons heeft beëindigd, met name wanneer u de computer gebruikt samen met anderen."
            />

            <TextCaption
                title="17. Externe links en inhoud"
                paragraph="De inhoud van onze websites werd samengesteld met de grootste zorgvuldigheid. Niettemin staan wij niet garant voor actualiteit en volledigheid. Wij zijn alleen verantwoordelijk voor eigen inhoud, niet voor externe inhoud. Voor aanvullende informatie vindt u op onze websites mogelijkerwijs links, die verwijzen naar webpagina's van derden. Wij hebben alle externe links gecontroleerd op inhoud in strijd met de wet, en op dat moment viel die niet te herkennen. Met betrekking tot externe inhoud bestaat geen algemene bewakings- en controleplicht. Bij kennis van inhoud in strijd met de wet controleren wij deze links meteen en worden ze eventueel onmiddellijk verwijderd. Externe links worden altijd geopend in een nieuw browservenster."
            />

            <TextCaption
                paragraph="Stand: maart 2022"
            />

        </div>
    );
}

export default PrivacyPolicyPage;